import {EditorSDK} from '@wix/editor-platform-sdk-types';
import {StoresWidgetID, SlotsMap} from '@wix/wixstores-client-core';

export interface IInstallReviewsPluginProps {
  editorSDK: EditorSDK;
  targetSlot: SlotsMap.ProductPageBottom | SlotsMap.ProductPageDetails;
  widgetPluginPointer: {appDefinitionId: string; widgetId: string};
}
/* Install a REVIEWS or RATINGS_SUMMARY plugin into the dedicated slots in product page */
export const installReviewsPlugin = async ({
  editorSDK,
  targetSlot,
  widgetPluginPointer,
}: IInstallReviewsPluginProps) => {
  const {widgetRef} = await getWidgetRefAndTypeByWidgetId(editorSDK, StoresWidgetID.PRODUCT_PAGE);
  const slots = await editorSDK.document.tpa.widgetPlugins.getWidgetSlots('', {widgetRef});

  if (targetSlot === SlotsMap.ProductPageBottom) {
    const mainReviewsSlot = slots.find((slot) => slot.role === SlotsMap.ProductPageBottom);
    await editorSDK.document.tpa.widgetPlugins.addWidgetPlugin('', {
      slotCompRef: mainReviewsSlot.compRef,
      widgetPluginPointer,
    });
  } else if (targetSlot === SlotsMap.ProductPageDetails) {
    const summaryReviewsSlot = slots.find((slot) => slot.role === SlotsMap.ProductPageDetails);
    await editorSDK.document.tpa.widgetPlugins.addWidgetPlugin('', {
      slotCompRef: summaryReviewsSlot.compRef,
      widgetPluginPointer,
    });
  }
};

const getWidgetRefAndTypeByWidgetId = async (editorSDK: EditorSDK, widgetId: string) => {
  const componentRefs = await editorSDK.document.components.getAllComponents('');

  const componentsOfType = [];
  await Promise.all(
    componentRefs.map(async (componentRef) => {
      const componentType = await editorSDK.document.components.getType('', {componentRef});

      if (
        componentType === 'wysiwyg.viewer.components.RefComponent' || // for blocks host
        componentType === 'wysiwyg.viewer.components.tpapps.TPAWidget' || // for OOI host
        componentType === 'wysiwyg.viewer.components.tpapps.TPAMultiSection' // for OOI host
      ) {
        componentsOfType.push(componentRef);
      }
    })
  );

  const withData = await Promise.all(
    componentsOfType.map(async (compRef) => ({
      compRef,
      data: (await editorSDK.document.components.data.get('', {
        componentRef: compRef,
      })) as any,
    }))
  );
  const found = withData.find((d) => d.data.widgetId === widgetId);
  if (!found) {
    return {};
  }
  return {widgetRef: found.compRef, isOOI: found.data.type === 'TPAWidget' || found.data.type === 'TPAMultiSection'};
};
