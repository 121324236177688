export interface IExperiments {
  [key: string]: 'true' | 'false' | string;
}

export class Experiments {
  constructor(private readonly experiments: IExperiments = {}) {}

  public enabled(specName: string): boolean {
    return this.experiments.hasOwnProperty(specName) && this.experiments[specName] === 'true';
  }
}
