import {EditorSDK} from '@wix/platform-editor-sdk';

export async function doTransaction(sdk: EditorSDK, fn: () => any) {
  try {
    return await sdk.document.transactions.runAndWaitForApproval('', fn);
  } catch (e) {
    const isTransactionError = await sdk.document.transactions.isConflictError('', e);
    if (isTransactionError) {
      return await sdk.document.transactions.runAndWaitForApproval('', fn);
    }
  }
}
