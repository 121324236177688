import {EcomComponent} from '@wix/ecom-platform-sdk';
import {ecomAppDefID, SPECS} from '../constants';
import {ExperimentsApi} from '../../common/experiments/ExperimentsApi';

interface AppEcomComponentsData {
  [key: string]: EcomComponentsData;
}

export interface EcomComponentsData {
  ecomComponents: {
    [key in EcomComponent]?: {
      isInstalled: boolean;
    };
  };
  managingAppId?: string;
}

export class EcomPlatformInstallationState {
  constructor(private readonly experimentsApi: ExperimentsApi) {
    this.experimentsApi = experimentsApi;
  }
  private ecomComponents: {[key: string]: EcomComponent[]} = {};
  private ecomComponentsData: AppEcomComponentsData = {};
  private readonly isNewEcomPlatformWrapper = this.experimentsApi.enabled(SPECS.NewEcomPlatformWrapper);

  public addInstallAppComponents(appDefId: string, installedComponents: EcomComponent[]) {
    this.ecomComponents[appDefId] = installedComponents;

    if (this.isNewEcomPlatformWrapper) {
      const configEcomCompToObj = installedComponents.reduce((result, item: EcomComponent) => {
        result[item] = {isInstalled: true};
        return result;
      }, {});

      this.ecomComponentsData[appDefId] = {
        ecomComponents: configEcomCompToObj as {
          [key in EcomComponent]?: {
            isInstalled: boolean;
          };
        },
        managingAppId: appDefId,
      };
    }
  }

  public addInstallAppComponentsData(appDefId: string, ecomComponents: EcomComponentsData) {
    this.ecomComponents[appDefId] = Object.keys(ecomComponents.ecomComponents).map((comp: EcomComponent) => comp);
    this.ecomComponentsData[appDefId] = ecomComponents;
  }

  public getTheAppEcomComponents(appDefId: string): EcomComponent[] {
    if (this.isNewEcomPlatformWrapper) {
      return Object.keys(this.ecomComponentsData[appDefId].ecomComponents).map((comp: EcomComponent) => comp);
    }
    return this.ecomComponents[appDefId];
  }

  public checkIfAppIsOnEcom(appDefId: string) {
    if (this.isNewEcomPlatformWrapper) {
      return !!this.ecomComponentsData[appDefId];
    }
    return !!this.ecomComponents[appDefId];
  }

  public getTheAppManageAppId(): string {
    if (this.isNewEcomPlatformWrapper) {
      if (this.getInstalledAppDefIds().length === 1) {
        const verticalAppId = this.getInstalledAppDefIds()[0];
        return this.ecomComponentsData[verticalAppId].managingAppId ?? verticalAppId;
      } else {
        return ecomAppDefID;
      }
    } else if (this.getInstalledAppDefIds().length === 1) {
      return this.getInstalledAppDefIds()[0];
    } else {
      return ecomAppDefID;
    }
  }

  public removeApp(appDefId: string) {
    if (this.isNewEcomPlatformWrapper && this.ecomComponentsData.hasOwnProperty(appDefId)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.ecomComponentsData[appDefId];
    } else if (this.ecomComponents.hasOwnProperty(appDefId)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.ecomComponents[appDefId];
    }
  }

  public hasInstalledApps() {
    return !!this.getInstalledAppDefIds().length;
  }

  public isMoreThanOneAppInstalled() {
    return this.getInstalledAppDefIds().length > 1;
  }

  public getInstalledAppDefIds() {
    if (this.isNewEcomPlatformWrapper) {
      return Object.keys(this.ecomComponentsData);
    }
    return Object.keys(this.ecomComponents);
  }
}
