import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {WidgetPluginInterfaces} from '@wix/widget-plugins-interfaces';
import {StoresWidgetID, SlotsMap} from '@wix/wixstores-client-core';

export const configureSlots = (appManifestBuilder: AppManifestBuilder): AppManifestBuilder =>
  appManifestBuilder
    .configureWidget(StoresWidgetID.CHECKOUT, (widgetBuilder) => {
      widgetBuilder.slots().set({
        [SlotsMap.CheckoutAboveSummary]: {
          displayName: 'Above The Summary Checkout Slot',
          interfaces: [WidgetPluginInterfaces.DEFAULT],
        },
        [SlotsMap.CheckoutSumary]: {
          displayName: 'Summary Checkout Slot',
          interfaces: [WidgetPluginInterfaces.DEFAULT],
        },
        [SlotsMap.CheckoutBelowSummary]: {
          displayName: 'Below The Summary Checkout Slot',
          interfaces: [WidgetPluginInterfaces.DEFAULT],
        },
      });
    })
    .configureWidget(StoresWidgetID.GRID_GALLERY, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.SLIDER_GALLERY, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.WISHLIST_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.GALLERY_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.CATEGORY_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.PRODUCT_PAGE, (widgetBuilder) => {
      widgetBuilder.slots().set({
        [SlotsMap.ProductPageDetails]: {
          displayName: 'Product Page Details Slot 1',
          interfaces: [WidgetPluginInterfaces.RATINGS_SUMMARY],
        },
        [SlotsMap.ProductPageBottom]: {
          displayName: 'Product Page Bottom Slot',
          interfaces: [WidgetPluginInterfaces.REVIEWS],
        },
      });
    });

const galleryWidgetBuilder = (widgetBuilder) => {
  widgetBuilder.slots().set({
    [SlotsMap.ProductGalleryDetails]: {
      displayName: 'Product Gallery Details Slot 1',
      interfaces: [WidgetPluginInterfaces.RATINGS_SUMMARY_OOI_LIST],
    },
  });
};
