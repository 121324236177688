import {EDITOR_SCRIPT_DSN} from '../editor-script/constants';

export function createSentryInstance(monitoring) {
  const configuration = {
    dataCallback: (data) => {
      data.environment = 'Worker';
      return data;
    },
  };
  return monitoring.createSentryMonitorForApp(EDITOR_SCRIPT_DSN, configuration);
}
